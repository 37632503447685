namespace $ {
	export class $mol_check_box extends $mol_check {
		
		/**
		 * ```tree
		 * Icon $mol_icon_tick
		 * ```
		 */
		@ $mol_mem
		Icon() {
			const obj = new this.$.$mol_icon_tick()
			
			return obj
		}
	}
	
}

