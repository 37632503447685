namespace $ {
	export class $mol_icon_tick extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z
		 * ```
		 */
		path() {
			return "M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
		}
	}
	
}

